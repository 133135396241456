<template>
  <v-form ref="CSchedule">
    <v-data-table
      v-model="selected"
      :disabled="disabled"
      :headers="headers"
      :items="weekdays"
      hide-default-footer
      :items-per-page="7"
      show-select
      item-key="day"
      :single-select="disabled"
      @input="tableSelectedEmit"
    >
      <template #[`item.data-table-select`]="{ isSelected, select }">
        <v-simple-checkbox
          :disabled="disabled"
          :ripple="false"
          color="primary"
          :value="isSelected"
          @input="select($event)"
        />
      </template>
      <template #[`item.day`]="{ item }">
        {{ $trans(item.day) }}
      </template>
      <template #[`item.start_time`]="{ item }">
        <template v-if="isSelectedItem(item)">
          <c-schedule-time
            :disabled="disabled"
            :item="item"
            where="start_time"
            :week="weekdays"
            :rules="[validation[item.day]]"
            @validation="validateDay($event, item)"
            @day="getAndSetDay"
          />
        </template>
        <template v-else>
          {{ notSelectedText }}
        </template>
      </template>
      <template #[`item.end_time`]="{ item }">
        <template v-if="isSelectedItem(item)">
          <c-schedule-time
            :disabled="disabled"
            :item="item"
            where="end_time"
            :week="weekdays"
            :rules="[validation[item.day]]"
            @validation="validateDay($event, item)"
            @day="getAndSetDay"
          />
        </template>
      </template>
    </v-data-table>
  </v-form>
</template>

<script>
import CScheduleTime from "./CScheduleTime";
import { defaultCalendarWeekDays } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "CSchedule",
  components: { CScheduleTime },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    allowDaysEditing: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    notSelectedText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      validation: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
      },
      selected: [],
      headers: [
        {
          text: this.$trans("resourceTimeGridDay"),
          align: "start",
          sortable: this.sortable,
          value: "day",
        },
        {
          text: this.$trans("from"),
          value: "start_time",
          sortable: this.sortable,
        },
        { text: this.$trans("to"), value: "end_time", sortable: this.sortable },
      ],
      weekdays: defaultCalendarWeekDays(),
    };
  },
  watch: {
    value(val) {
      val && this.setSchedule();
    },
  },
  mounted() {
    this.setSchedule();
  },
  methods: {
    validateDay($event) {
      this.validation[$event.day] = $event.result;
    },
    isSelectedItem(item) {
      const result = this.selected.filter((obj) => obj.day === item.day);
      return result.length > 0;
    },
    setSchedule() {
      if (this.value && this.value.length) {
        this.selected = this.value;
        this.updateWeekdays();
      }
    },
    getAndSetDay(val) {
      this.updateWeekdays(val);
      this.updateSelected(val);
      this.$emit("change", this.getSelected());
    },
    tableSelectedEmit() {
      this.$emit("change", this.getSelected());
    },
    updateWeekdays(value) {
      this.weekdays.map((weekday) => {
        const val = value || this.value.find((x) => x.day === weekday.day);
        if (val && weekday.day === val.day) {
          weekday.start_time = val.start_time;
          weekday.end_time = val.end_time;
        }
      });
    },
    getSelected() {
      return this.selected.map((select) => {
        const parsed = select;

        if (!this.validation[parsed.day]) {
          const startTime = this.$moment(
            parsed.start_time,
            this.$helpers.timeFormat
          );

          select.start_time = parsed.start_time;
          select.end_time = startTime
            .add(60, "minutes")
            .format(this.$helpers.timeFormat);
        }

        return parsed;
      });
    },
    updateSelected(val) {
      this.selected.map((select) => {
        if (select.day === val.day) {
          select.day = val.day;
          select.start_time = val.start_time;
          select.end_time = val.end_time;
        }
      });
    },
  },
};
</script>

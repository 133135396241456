<template>
  <div v-if="$vuetify.breakpoint.width <= 960">
    {{ item[where] }}
    <c-schedule-time-picker
      :disabled="disabled"
      :item="item"
      :where="where"
      :week="week"
      @day="$emit('day', $event)"
    />
  </div>
  <c-schedule-time-input
    v-else
    :item="item"
    :where="where"
    :week="week"
    :rules="rules"
    :disabled="disabled"
    @validation="$emit('validation', $event)"
    @day="$emit('day', $event)"
  />
</template>

<script>
import CScheduleTimePicker from "./CScheduleTimePicker";
import CScheduleTimeInput from "./CScheduleTimeInput";

export default {
  name: "CScheduleTime",
  components: { CScheduleTimePicker, CScheduleTimeInput },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    where: {
      type: String,
      default: null,
    },
    week: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
  },
  watch: {
    value() {
      this.selectedTime = this.value;
    },
  },
};
</script>

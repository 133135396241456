import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  mixins: [sharedActions],
  data() {
    return {
      showMenu: false,
      uniqueId: this.generateRandomString(12),
    };
  },
  watch: {
    showMenu(val) {
      if (val) {
        setTimeout(() => {
          const index = this.timeSlots.indexOf(this.selectedTime);
          const element = document.getElementById(
            this.uniqueId + "-list-item-" + index
          );

          if (element) {
            element.scrollIntoView();
          }
        }, 100);
      }
    },
  },
  methods: {
    validateInterval() {
      return "00:00";
    },
    processInput(input) {
      // Check if input is empty
      if (!input) {
        return this.validateInterval();
      }

      let hours, minutes;

      // Check if input includes ":"
      if (input.includes(":")) {
        // Split input into hours and minutes
        [hours, minutes] = input.split(":");
      } else {
        // If input doesn't include ":", assume it's just the hour
        hours = input;
        minutes = "00";
      }

      // Check if hours and minutes are valid numbers
      if (isNaN(hours) || isNaN(minutes)) {
        return this.validateInterval();
      }

      // Convert hours and minutes to numbers
      hours = Number(hours);
      minutes = Number(minutes);

      // Check if hours are within the valid range
      if (hours < 0 || hours > 23) {
        return this.validateInterval();
      }

      // Check if minutes are within the valid range
      if (minutes < 0 || minutes > 59) {
        return this.validateInterval();
      }

      // Convert hours and minutes back to strings
      hours = hours.toString();
      minutes = minutes.toString();

      // Add leading zero to hours if needed
      if (hours.length === 1) {
        hours = `0${hours}`;
      }

      // Add leading zero to minutes if needed
      if (minutes.length === 1) {
        minutes = `0${minutes}`;
      }

      return `${hours}:${minutes}`;
    },
  },
};

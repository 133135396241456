<template>
  <v-menu
    v-model="showMenu"
    transition="slide-y-transition"
    nudge-bottom="40"
    bottom
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="selectedTime"
        v-mask="'##:##'"
        class="c-time-input"
        dense
        outlined
        v-bind="attrs"
        :rules="rules"
        hide-details="auto"
        @blur="onBlur(selectedTime)"
        @focus="$event.target.select()"
        v-on="on"
      >
        <template #append>
          <v-icon v-on="on"> $clock</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-list class="c-text-field-list">
      <v-list-item-group>
        <v-list-item
          v-for="(el, i) in timeSlots"
          :id="uniqueId + '-list-item-' + i"
          :key="i"
          @click="onSelect(el)"
        >
          <v-list-item-content>
            <v-list-item-title v-text="el" />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import { generateTimes } from "@/lib/calendesk-js-library/tools/helpers";
import timeInputActions from "@/calendesk/mixins/timeInputActions";

export default {
  name: "CSchedulePicker",
  mixins: [timeInputActions],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    where: {
      type: String,
      default: null,
    },
    week: {
      type: Array,
      default: null,
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      selectedTime: this.item[this.where],
    };
  },
  computed: {
    ...mapGetters({
      configuration: "setup/getConfiguration",
    }),
    timeSlots() {
      if (this.where === "start_time") {
        return generateTimes("00:00", "23:59", 5);
      }
      const from = this.$moment(
        this.item.start_time,
        this.$helpers.timeFormat
      ).add(5, "minutes");
      return generateTimes(from, "23:59", 5);
    },
  },
  watch: {
    week: {
      handler() {
        this.selectedTime = this.item[this.where];
      },
      deep: true,
    },
  },
  methods: {
    onBlur(val) {
      if (this.selectedTime && this.selectedTime.length) {
        this.selectedTime = this.processInput(val);
        const obj = JSON.parse(JSON.stringify(this.item));
        obj[this.where] = this.selectedTime;
        const startTime = this.$moment(
          obj.start_time,
          this.$helpers.timeFormat
        );
        const endTime = this.$moment(obj.end_time, this.$helpers.timeFormat);
        this.$emit("validation", {
          day: obj.day,
          result: !startTime.isSameOrAfter(endTime),
        });
        this.$emit("day", obj);
      }
    },
    onSelect(item) {
      this.onBlur(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.c-text-field-list {
  height: 200px;
  overflow-y: auto;
}

.c-time-input {
  width: 100px;
}
</style>

<template>
  <v-dialog
    v-model="dialog[item.day]"
    :disabled="disabled"
    persistent
    :return-value.sync="time"
    width="290px"
  >
    <template #activator="{ on }">
      <v-btn :disabled="disabled" icon v-on="on">
        <v-icon v-text="'$clock'" />
      </v-btn>
    </template>
    <v-time-picker
      v-if="dialog[item.day]"
      v-model="time"
      :disabled="disabled"
      :allowed-hours="allowedHours"
      full-width
      format="24hr"
    >
      <v-spacer />
      <v-btn
        :disabled="disabled"
        text
        color="primary"
        @click="dialog[item.day] = false"
      >
        {{ $trans("cancel") }}
      </v-btn>
      <v-btn :disabled="disabled" text color="primary" @click="save">
        OK
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
export default {
  name: "CScheduleInput",
  props: {
    item: {
      type: Object,
      default: null,
    },
    where: {
      type: String,
      default: null,
    },
    week: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
      time: this.item[this.where],
    };
  },
  watch: {
    week: {
      handler(val) {
        this.time = this.item[this.where];
      },
      deep: true,
    },
  },
  methods: {
    allowedHours(v) {
      const endHour = this.item.end_time.split(":")[0];
      const startHour = this.item.start_time.split(":")[0];
      return this.where === "start_time" ? v < endHour : v > startHour;
    },
    save() {
      const obj = JSON.parse(JSON.stringify(this.item));
      obj[this.where] = this.time;
      this.$emit("day", obj);
      this.dialog[this.item.day] = false;
    },
  },
};
</script>

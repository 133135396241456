<template>
  <v-dialog
    ref="dialog"
    v-model="timeModal"
    :return-value.sync="time"
    persistent
    width="290px"
  >
    <template #activator="{ on }">
      <v-text-field
        v-model="time"
        :outlined="isOutlined"
        :append-icon="appendIcon ? appendIcon : undefined"
        :label="label"
        readonly
        :rules="rules"
        :disabled="disabled"
        hide-details="auto"
        v-on="on"
      />
    </template>
    <v-time-picker
      v-if="timeModal"
      v-model="time"
      full-width
      format="24hr"
      :disabled="disabled"
      :min="min"
      :max="max"
      @input="$emit('input', time)"
    >
      <slot>
        <v-spacer />
        <v-btn text color="primary" @click="timeModal = false">
          {{ $trans("cancel") }}
        </v-btn>
        <v-btn text color="primary" @click="$refs.dialog.save(time)">
          {{ $trans("ok") }}
        </v-btn>
      </slot>
    </v-time-picker>
  </v-dialog>
</template>

<script>
import { parseTime } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "DialogTimeInput",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "false",
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
    max: {
      type: String,
      default: "23:59",
    },
    min: {
      type: String,
      default: "00:00",
    },
  },
  data() {
    return {
      timeModal: false,
      time: this.value,
    };
  },
  watch: {
    value() {
      this.time = this.value;
    },
    min(val) {
      if (val && this.time && parseTime(val) > parseTime(this.value)) {
        this.time = null;
      }
    },
  },
};
</script>

<template>
  <v-form ref="form" @submit.prevent="handleSave">
    <v-container class="white">
      <v-row>
        <v-col class="pa-0 ma-0">
          <v-container fluid>
            <v-row class="pb-0 mb-0">
              <v-col class="d-flex align-center pb-0 mb-0" cols="12">
                <v-checkbox
                  v-model="form.app_calendar_employee_working_hours_visible"
                  :disabled="isLoading"
                  :label="$trans('show_employees_working_hours')"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row class="pb-0 mb-0 mt-0 pt-0">
              <v-col class="d-flex align-center pb-0 mb-0 mt-0 pt-0" cols="12">
                <v-checkbox
                  v-model="form.app_calendar_do_not_group_bookings"
                  :disabled="isLoading"
                  :label="$trans('do_not_group_booking_checkbox_label')"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row class="mt-0 pt-0 mb-2">
              <v-col class="d-flex align-center pt-0 mt-0" cols="12">
                <v-checkbox
                  v-model="form.app_calendar_show_canceled"
                  :disabled="isLoading"
                  :label="$trans('show_canceled_bookings')"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" class="d-flex align-center">
                <span>{{ $trans("minimal_length_of_calendar_event") }}:</span>
              </v-col>
              <v-col cols="12" md="3" class="d-flex align-center">
                <v-select
                  v-model="form.calendar_slots_duration"
                  outlined
                  :items="selectableTimes"
                  :disabled="isLoading"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" class="d-flex align-center">
                <span>{{ $trans("hour_divider_interval") }}:</span>
              </v-col>
              <v-col cols="12" md="3" class="d-flex align-center">
                <v-select
                  v-model="form.calendar_slots_label_interval"
                  outlined
                  :items="selectableTimes"
                  :disabled="isLoading"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" class="d-flex align-center">
                <span>{{ $trans("calendar_time_boundaries") }}:</span>
              </v-col>
              <v-col class="d-flex align-center" cols="6" md="3">
                <dialog-time-input
                  v-model="form.calendar_time.from"
                  :label="$trans('from')"
                  :rules="[rules.timeNotGreaterThan(form.calendar_time.to)]"
                  is-outlined
                  :disabled="isLoading"
                />
              </v-col>
              <v-col class="d-flex align-center" cols="6" md="3">
                <dialog-time-input
                  v-model="form.calendar_time.to"
                  :label="$trans('to')"
                  :rules="[rules.timeNotLighterThan(form.calendar_time.from)]"
                  is-outlined
                  :disabled="isLoading"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" class="d-flex align-center">
                <div>
                  <span
                    >{{ $trans("profile_company_work_hours_header") }}:</span
                  >
                  <template
                    v-if="$adminConfig('app_employees_time_zone_enabled')"
                  >
                    <br />{{ $trans("timezone") }}: {{ platformTimeZone }}
                  </template>
                </div>
              </v-col>
              <v-col cols="12" md="8">
                <c-schedule
                  ref="CompanySchedule"
                  v-model="companyWorkingHours"
                  :disabled="isLoading"
                  :not-selected-text="$trans('closed')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DialogTimeInput from "@/views/dashboard/pages/Calendar/components/DialogTimeInput";
import {
  timeNotGreaterThan,
  timeNotLighterThan,
} from "@/lib/calendesk-js-library/forms/validators";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import CSchedule from "@/components/CSchedule/CSchedule";
import api from "@/lib/calendesk-js-library/api/api";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "CalendarSettingsForm",
  components: { CSchedule, DialogTimeInput },
  mixins: [sharedActions],
  data() {
    return {
      isLoading: false,
      companyWorkingHours: null,
      companyWorkingHoursApi: [],
      rules: {
        timeNotGreaterThan,
        timeNotLighterThan,
      },
      form: {
        calendar_slots_duration: null,
        calendar_slots_label_interval: null,
        app_calendar_show_canceled: false,
        app_calendar_do_not_group_bookings: false,
        app_calendar_employee_working_hours_visible: false,
        calendar_time: {
          from: null,
          to: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      configuration: "setup/getConfiguration",
      adminConfiguration: "setup/getAdminConfiguration",
    }),
    selectableTimes() {
      const selectableTimes = [];
      const multiplier = 5;
      const iterations = 12;
      let duration = this.$moment.duration(1, "minutes");

      selectableTimes.push({
        text: `${duration.asMinutes()} min`,
        value: duration.format("*hh:mm", { trunc: true }),
      });

      for (let i = 1; i <= iterations; i++) {
        duration = this.$moment.duration(multiplier * i, "minutes");

        selectableTimes.push({
          text: `${duration.asMinutes()} min`,
          value: duration.format("*hh:mm", { trunc: true }),
        });
      }

      return selectableTimes;
    },
  },
  mounted() {
    this.getCompanyWorkingHours();

    this.$nextTick().then(() => {
      const {
        calendar_slots_duration,
        calendar_slots_label_interval,
        calendar_time,
      } = this.configuration;

      const {
        app_calendar_show_canceled,
        app_calendar_do_not_group_bookings,
        app_calendar_employee_working_hours_visible,
      } = this.adminConfiguration;

      this.form = {
        calendar_slots_duration,
        calendar_slots_label_interval,
        app_calendar_show_canceled: !!app_calendar_show_canceled,
        app_calendar_do_not_group_bookings:
          !!app_calendar_do_not_group_bookings,
        app_calendar_employee_working_hours_visible:
          !!app_calendar_employee_working_hours_visible,
        calendar_time: {
          from: calendar_time.from,
          to: calendar_time.to,
        },
      };
    });
  },
  methods: {
    ...mapActions({
      updateConfiguration: "setup/updateConfiguration",
      fetchConfiguration: "setup/fetchConfiguration",
    }),
    handleSave() {
      if (
        !this.$refs.form.validate() ||
        !this.$refs.CompanySchedule.$refs.CSchedule.validate()
      ) {
        errorNotification("form_is_invalid", {}, false);
        return;
      }

      pushEvent("updateCalendarSettings");

      this.isLoading = true;
      this.$emit("ctaButtonLoading", true);

      const requests = [
        this.updateCompanyWorkingHours(),
        this.updateConfiguration(this.form),
      ];

      Promise.all(requests)
        .then(async () => {
          await this.fetchConfiguration();
          this.$root.$emit("reloadCalendarSettings");
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            errorNotification("insufficient_permissions_error", error, false);
          } else {
            errorNotification(null, error);
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.$emit("ctaButtonLoading", false);
          this.$emit("close", true);
        });
    },
    async getCompanyWorkingHours() {
      try {
        this.isLoading = true;
        const response = await api.getCompanyWorkingHours();
        this.companyWorkingHoursApi = response.data;
        this.companyWorkingHours = this.workingHoursConvertToComponent(
          response.data
        );
      } catch (err) {
        errorNotification("operation_failed", err);
      }

      this.isLoading = false;
    },
    updateCompanyWorkingHours() {
      this.workingHoursConvertToApi();
      return api.updateCompanyWorkingHours({
        days: this.companyWorkingHoursApi,
      });
    },
    workingHoursConvertToComponent(array) {
      const result = [];
      array
        .filter((item) => item.is_open)
        .forEach((item) => {
          const newItem = {};
          newItem.day = item.day;
          newItem.start_time = item.open;
          newItem.end_time = item.close;
          result.push(newItem);
        });
      return result;
    },
    workingHoursConvertToApi() {
      this.companyWorkingHoursApi.map((api) => {
        const day = this.companyWorkingHours.find(
          (item) => api.day === item.day
        );
        if (day) {
          api.is_open = true;
          api.open = day.start_time;
          api.close = day.end_time;
        } else {
          api.is_open = false;
          api.open = "08:00";
          api.close = "17:00";
        }
      });
    },
  },
};
</script>
